export function showFields(appointment_overlay) {
  return (
    appointment_overlay.patient_option === 'new' ||
    appointment_overlay.existing_patient_no_treatments === false
  )
}

export function showCreateTreatmentAction(appointment_overlay) {
  return (
    appointment_overlay.patient_option === 'existing' &&
    appointment_overlay.existing_patient_no_treatments === true
  )
}
