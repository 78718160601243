import { useMutation } from 'Data/Api.js'
import mutationUpdateScheduleNote from './mutation-update-schedule-note.graphql.js'
import mutationUpdateAppointmentDuration from './mutation-update-appointment-duration.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionUpdateNote = useDataOnActionUpdateNote(props, data)
  let onActionUpdateAppointmentDuration =
    useDataOnActionUpdateAppointmentDuration(props, data)

  return async function onSubmit({ args, ...otherProps }) {
    switch (args.type) {
      case 'updateScheduleNote': {
        return await onActionUpdateNote({ args, ...otherProps })
      }
      case 'updateAppointmentDuration': {
        return await onActionUpdateAppointmentDuration({ args, ...otherProps })
      }
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUpdateNote(props, data) {
  let [, executeMutation] = useMutation(mutationUpdateScheduleNote)

  // @ts-ignore
  let [, notify] = useNotifications()
  return async function onActionUpdateNoteDuration({ args }) {
    let { id, duration, start_time } = args

    if (typeof duration !== 'undefined' || typeof start_time !== 'undefined') {
      let mutationResponse = await executeMutation({
        id,
        data: typeof duration !== 'undefined' ? { duration } : { start_time },
      })

      if (mutationResponse.error) {
        notify(
          notifyError(
            typeof duration !== 'undefined'
              ? 'Could not update the duration. Please try again.'
              : 'Could not update the start time. Please try again.'
          )
        )
        return false
      }
    }

    return true
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUpdateAppointmentDuration(props, data) {
  let [, executeMutation] = useMutation(mutationUpdateAppointmentDuration)

  // @ts-ignore
  let [, notify] = useNotifications()
  return async function onActionUpdateNoteDuration({ args }) {
    let { id, duration } = args

    let mutationResponse = await executeMutation({
      id,
      duration,
    })

    if (mutationResponse.error) {
      notify(notifyError('Could not update appointment duration'))
      args.revert?.()
      return false
    }

    notify(notifySuccess('Appointment duration updated successfully'))
    return true
  }
}
