import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick(action) {
    switch (action) {
      case 'DELETE':
        return deleteAppointment()
      case 'SCHEDULE_VIA_SMS_OR_EMAIL':
        return scheduleViaSmsOrEmail()
      case 'GENERATE_LETTERS':
        return generateLetters()
      case 'APPOINTMENT_HISTORY':
        return showAppointmentHistory()
      case 'APPOINTMENT_ADD':
        return addAppointment()
      case 'MOVE_TO_ANOTHER_TREATMENT':
        return moveToAnotherTreatment()
      case 'CHANGE_APPOINTMENT_DURATION':
        return changeAppointmentDuration()
      default:
        return
    }
  }

  function deleteAppointment() {
    setFlowTo(normalizePath(props.viewPath, 'Delete/Content'))
  }

  function addAppointment() {
    setFlowTo(normalizePath(props.viewPath, 'AddAppointment/Content'))
  }

  function showAppointmentHistory() {
    setFlowTo(normalizePath(props.viewPath, 'AppointmentHistory/Content'))
  }

  async function scheduleViaSmsOrEmail() {
    setFlowTo(normalizePath(props.viewPath, 'ScheduleViaSmsOrEmail/Content'))
  }

  function generateLetters() {
    setFlowTo(normalizePath(props.viewPath, 'GenerateLetters/Content'))
  }

  function moveToAnotherTreatment() {
    setFlowTo(normalizePath(props.viewPath, 'MoveToAnotherTreatment/Content'))
  }

  function changeAppointmentDuration() {
    setFlowTo(
      normalizePath(props.viewPath, 'ChangeAppointmentDuration/Content')
    )
  }
}
