// this is an autogenerated file from DesignSystem/TimelineEvent/Order/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Order($id: uuid!) {
  timeline_events_by_pk(id: $id) {
    id
    title: data(path: "title")
    status: data(path: "status")
    order_id: data(path: "orderId")
    tracking_id: data(path: "trackingId")
    tracking_url: data(path: "trackingUrl")
    carrier: data(path: "carrier")
    created_at
  }
}

`