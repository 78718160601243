import { useEffect, useMemo } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data'
import {
  timeToMinutes,
  minutesToThreeDigitsTime,
  minutesToTimestamp,
} from 'Data/format'
import { APPOINTMENT_TYPE_COLORS } from 'Data/constants'
import { addMinutes, formatISO, getDay, parseISO } from 'date-fns'
import { last, sortBy } from 'lodash'

let ADJUSTED_SLOT_INTERVAL = {
  5: 5,
  10: 5,
  30: 5,
}

let DEFAULT_RESOURCE_WIDTH = 167

// This third data context -calendar- is responsible for gathering
// and calculating all the information we need to display in
// the topbar and in the timegrid

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let resources = useDataValue({
    context: 'resources',
    path: 'chairs',
    viewPath: props.viewPath,
  })
  let events = useDataValue({
    context: 'events',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let time_zone_id = useDataValue({
    context: 'tab',
    path: 'selected.time_zone_id',
    viewPath: props.viewPath,
  })
  let slot_interval = useDataValue({
    context: 'settings',
    path: 'slot_interval',
    viewPath: props.viewPath,
  })
  let chairs_in_view = useDataValue({
    context: 'settings',
    path: 'chairs_in_view',
    viewPath: props.viewPath,
  })
  let selected_appointment_templates = useDataValue({
    context: 'tab',
    path: 'selected.topbar_appointment_templates',
    viewPath: props.viewPath,
  })
  let sidebar_appointment_templates = useDataValue({
    context: 'tab',
    path: 'selected.sidebar_appointment_templates',
    viewPath: props.viewPath,
  })
  let sidebar_employee_resources = useDataValue({
    context: 'tab',
    path: 'selected.sidebar_employee_resources',
    viewPath: props.viewPath,
  })

  let scheduling_slots = useDataValue({
    context: 'tab',
    path: 'scheduling.slots',
    viewPath: props.viewPath,
  })
  let scheduling_appointment_id = useDataValue({
    context: 'tab',
    path: 'scheduling.appointment_id',
    viewPath: props.viewPath,
  })
  let scheduling_slot_id = useDataValue({
    context: 'tab',
    path: 'scheduling.slot_id',
    viewPath: props.viewPath,
  })
  let scheduling_untemplated_slot = useDataValue({
    context: 'tab',
    path: 'scheduling.untemplated_slot',
    viewPath: props.viewPath,
  })

  let appointment_type_name = useDataValue({
    context: 'tab',
    path: 'scheduling.appointment_type_name',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'appointment_overlay.is_location_changing',
  })

  let timegrid_width = useDataValue({
    context: 'tab',
    path: 'timegrid_width',
    viewPath: props.viewPath,
  })

  // Appointment bookings are events coming from -events-
  // data context and the result of this memo contains
  // the appointment bookings themselves properly formatted,
  // and the amount of patients and appointments for
  // the specified date
  let appointment_bookings = useMemo(() => {
    let patients = new Set()
    let bookings = events.appointment_bookings
      .filter(item => {
        // we need to hide it when rescheduling
        return item.appointment._id !== scheduling_appointment_id
      })
      .map(item => {
        patients.add(item.appointment.patient._id)

        let local_end_time = minutesToThreeDigitsTime(
          timeToMinutes(item.local_start_time) + item.duration
        )

        return {
          id: item._id,
          vaxiom_id: item.id,
          chair_id: item.chair.id,
          location_id,
          appointment: item.appointment,
          state: item.state,
          confirmation_status: item.confirmation_status,
          appointment_type_color:
            APPOINTMENT_TYPE_COLORS[item.appointment.type.display_color_id],
          // timegrid props
          resourceId: item.chair._id,
          start: `${item.local_start_date}T${item.local_start_time}`,
          end: `${item.local_start_date}T${local_end_time}`,
          type: 'appointment',
          overlap: false,
          durationEditable: true,
        }
      })

    return {
      bookings,
      patient_count: patients.size,
      appointment_count: bookings.length,
    }
  }, [events, location_id, scheduling_appointment_id])

  function doesNoteOverlapAnyBooking(note, bookings) {
    let noteStart = new Date(note.start)
    let noteEnd = new Date(note.end)

    for (let booking of bookings) {
      let bookingStart = new Date(booking.start)
      let bookingEnd = new Date(booking.end)

      // Check for overlap condition:
      // Overlap if noteStart < bookingEnd AND bookingStart < noteEnd
      if (noteStart < bookingEnd && bookingStart < noteEnd) {
        return true
      }
    }

    return false
  }

  // Schedule notes are events coming from -events-
  // data context and the result of this memo contains
  // blocked times and calendar notes properly formatted
  // and their corresponding alerts
  let schedule_notes = useMemo(() => {
    let notes_and_blocked_times = events.schedule_notes.map(item => {
      let note = {
        id: item._id,
        content: item.content,
        // timegrid props
        start: item.start_time,
        duration: item.duration,
        end: formatISO(addMinutes(parseISO(item.start_time), item.duration)),
        resourceId: item.chair._id,
        type: item.is_blocking_time ? 'blockTime' : 'note',
        display: 'block',
        alert_this_day: item.alert_this_day ?? false,
        sys_created: item.sys_created,
        created_by: item.created_by,
        is_blocking_time: item.is_blocking_time,
        startEditable: true,
        durationEditable: true,
      }

      if (
        note.type === 'note' &&
        doesNoteOverlapAnyBooking(
          note,
          appointment_bookings.bookings.filter(
            booking => booking.resourceId === note.resourceId
          )
        )
      ) {
        note.display = 'background'
      }

      return note
    })

    return {
      notes_and_blocked_times,
      alerts: notes_and_blocked_times.filter(item => item.alert_this_day),
    }
  }, [appointment_bookings.bookings, events.schedule_notes])

  // Appointment slots are events coming from -resources-
  // data context and represent the slots of the appointment
  // types selected in the topbar filter.
  let appointment_slots = useMemo(() => {
    return resources.flatMap(
      resource =>
        resource.appointment_slots
          .filter(item =>
            selected_appointment_templates.includes(
              item.appointment_template_id
            )
          )
          .flatMap(item => {
            return item.slots.map(slot => ({
              appointment_type: item.appointment_template.full_name_computed,
              start: minutesToTimestamp(date, time_zone_id, slot.start_min),
              end: minutesToTimestamp(date, time_zone_id, slot.end_min),
              backgroundColor:
                APPOINTMENT_TYPE_COLORS[
                  item.appointment_template.color_id_computed
                ] + '66',
              headerBackgroundColor:
                APPOINTMENT_TYPE_COLORS[
                  item.appointment_template.color_id_computed
                ],
              headerColor: '#ffffff',
              type: 'appointment-type',
              resourceId: resource.id,
              display: 'background',
            }))
          }) || []
    )
  }, [resources, date, time_zone_id, selected_appointment_templates])

  // Slots are events coming from appointment overlay
  // through -tab- data context and represent the available
  // candidate slots when the user is scheduling an appointment
  let slots = useMemo(() => {
    let untemplated_slots = []
    let templated_slots = scheduling_slots.map(item => {
      let start = minutesToTimestamp(date, time_zone_id, item.start_min)
      let previewEnd = minutesToTimestamp(
        date,
        time_zone_id,
        item.start_min + ADJUSTED_SLOT_INTERVAL[slot_interval]
      )
      let realEnd = minutesToTimestamp(date, time_zone_id, item.end_min)
      let selected = item.id === scheduling_slot_id

      return {
        id: item.id,
        start,
        end: selected ? realEnd : previewEnd,
        classNames: [selected ? 'candidate-selected' : 'candidate'],
        type: 'candidate',
        display: 'background',
        schedulingSlot: item,
        previewEnd,
        realEnd,
        selected,
        resourceId: item.chair_id,
        description: `${appointment_type_name} - ${item.chair_name}`,
      }
    })

    if (scheduling_untemplated_slot.id) {
      untemplated_slots.push({
        id: scheduling_untemplated_slot.id,
        type: 'candidate-untemplated',
        classNames: ['candidate-untemplated'],
        display: 'background',
        start: minutesToTimestamp(
          date,
          time_zone_id,
          scheduling_untemplated_slot.start_min
        ),
        end: minutesToTimestamp(
          date,
          time_zone_id,
          scheduling_untemplated_slot.end_min
        ),
        resourceId: scheduling_untemplated_slot.chair_id,
        config: scheduling_untemplated_slot,
        description: `${appointment_type_name} - ${
          resources.find(
            resource => resource.id === scheduling_untemplated_slot.chair_id
          )?.name
        }`,
      })
    }

    return [...templated_slots, ...untemplated_slots]
  }, [
    scheduling_untemplated_slot,
    scheduling_slots,
    scheduling_slot_id,
    date,
    time_zone_id,
    slot_interval,
    appointment_type_name,
    resources,
  ])

  // Available resources come from -resources- data context and
  // the result of this memo contains those resources that are
  // open (calculated based on chair configuration and appointment
  // bookings defined for each resource at the speficied date),
  // and the start and end time for the timegrid definition
  let resources_settings = useMemo(() => {
    let open_resources = resources
      .map(item => ({
        ...item,
        businessHours: getBusinessHours(item),
      }))
      .filter(item => item.businessHours.length)

    // Apply appointment template and allocations filter
    // from sidebar here to not add complexity and downgrade
    // the performance of the query
    let filtered_resources = open_resources

    if (sidebar_appointment_templates.length) {
      filtered_resources = filtered_resources.filter(item =>
        item.appointment_slots.some(appt_slot =>
          sidebar_appointment_templates.includes(
            appt_slot.appointment_template_id
          )
        )
      )
    }

    if (sidebar_employee_resources.length) {
      filtered_resources = filtered_resources.filter(item =>
        item.allocations.some(allocation =>
          sidebar_employee_resources.includes(allocation.id)
        )
      )
    }

    return {
      resources: filtered_resources,
      ...calculateSlotMinMaxTime(open_resources),
    }

    /**
     *
     * @param {Object} resource
     * @returns {Array<Object>}
     */
    function getBusinessHours(resource) {
      // No office hours case: chair depends on existing appointments
      if (!resource.office_hours?.length) {
        let events = [
          ...appointment_bookings.bookings.filter(
            item => item.resourceId === resource.id
          ),
          ...schedule_notes.notes_and_blocked_times.filter(
            item => item.resourceId === resource.id
          ),
        ]
        // No bookings, chair closed and hidden
        if (!events.length) return []

        // Existing bookings, chair office hours based on first and last bookings
        let sorted_events = sortBy(events, ['start'])
        return [
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
              item => item !== getDay(date)
            ),
            startTime: sorted_events[0].start.split('T')[1],
            endTime: last(sorted_events).end.split('T')[1],
          },
        ]
      }

      // No appointment slots: chair is displayed but closed
      if (!resource.appointment_slots?.length) {
        return [
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(
              item => item !== getDay(date)
            ),
            startTime: minutesToThreeDigitsTime(
              resource.office_hours[0].start_min
            ),
            endTime: minutesToThreeDigitsTime(
              last(resource.office_hours).end_min
            ),
          },
        ]
      }

      // Office hours and appointment slots available
      return resource.office_hours.map(item => ({
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        startTime: minutesToThreeDigitsTime(item.start_min),
        endTime: minutesToThreeDigitsTime(item.end_min),
      }))
    }

    /**
     * @returns {{
     *   slot_min_time: string
     *   slot_max_time: string
     * }}
     */
    function calculateSlotMinMaxTime(resources) {
      let slot_min_time = 24 * 60
      let slot_max_time = 0
      let start_time, end_time

      resources.forEach(item => {
        start_time = timeToMinutes(item.businessHours[0].startTime)
        end_time = timeToMinutes(last(item.businessHours).endTime)
        if (start_time < slot_min_time) slot_min_time = start_time
        if (end_time > slot_max_time) slot_max_time = end_time
      })

      return {
        slot_min_time: minutesToThreeDigitsTime(slot_min_time),
        slot_max_time: minutesToThreeDigitsTime(slot_max_time),
      }
    }
  }, [
    resources,
    appointment_bookings,
    date,
    sidebar_appointment_templates,
    sidebar_employee_resources,
  ])

  useEffect(() => {
    change(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appointment_bookings,
    schedule_notes,
    appointment_slots,
    slots,
    resources_settings,
  ])

  // Each element is calculated separately and then included here
  // in the final result of the data context -calendar-
  // This avoids having to recalculate everything
  // every time something changes
  return useMemo(() => {
    return {
      events: [
        ...appointment_bookings.bookings,
        ...schedule_notes.notes_and_blocked_times,
        ...appointment_slots,
        ...slots,
      ],
      alerts: schedule_notes.alerts,
      ...resources_settings,
      appointment_count: appointment_bookings.appointment_count,
      patient_count: appointment_bookings.patient_count,
      resource_width:
        timegrid_width && chairs_in_view
          ? Math.floor((timegrid_width - 112) / chairs_in_view)
          : DEFAULT_RESOURCE_WIDTH,
    }
  }, [
    appointment_bookings,
    schedule_notes,
    appointment_slots,
    slots,
    resources_settings,
    timegrid_width,
    chairs_in_view,
  ])
}
