import React, { useMemo } from 'react'
import {
  useReactTable,
  flexRender,
  getExpandedRowModel,
  getCoreRowModel,
} from '@tanstack/react-table'
import { useDataChange, useDataValue } from 'Simple/Data'
import { convertTxPlanWeeksToMonths, dataValueFormat } from 'Data/format'

import ScrollIntoViewIfNeeded from 'DesignSystem/ScrollIntoViewIfNeeded/react.js'
import TysiaIconKeyboardArrowUpFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowUpFilled'
import TysiaIconKeyboardArrowDownFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowDownFilled'
import TysiaCheckboxInput from 'design-system/TysiaCheckbox/TysiaCheckboxInput'
import TysiaCheckboxText from 'design-system/TysiaCheckbox/TysiaCheckboxText'
import TysiaCheckbox from 'design-system/TysiaCheckbox'
import Header from './Header/index.js'

import View from './view.js'

function TreatmentTable(props) {
  let scrollToId = useDataValue({
    context: 'scroll_to',
    path: 'id',
    viewPath: props.viewPath,
  })
  let rowSelection = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let setRowSelection = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let columns = useMemo(
    () => [
      {
        header: 'Treatment Name',
        accessorKey: 'name',
        cell: cell => {
          if (cell.row.getCanExpand()) {
            return (
              <>
                {scrollToId === cell.row.original.id ? (
                  <ScrollIntoViewIfNeeded />
                ) : null}
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                    display: 'flex',
                    flex: 'row',
                    alignItems: 'center',
                  }}
                >
                  {cell.row.original.name}
                  {cell.row.getIsExpanded() ? (
                    <TysiaIconKeyboardArrowUpFilled
                      fill={props.collapseIconColor}
                      viewPath={`${props.viewPath}/Tysiav2ChevronUp`}
                    />
                  ) : (
                    <TysiaIconKeyboardArrowDownFilled
                      fill={props.collapseIconColor}
                      viewPath={`${props.viewPath}/Tysiav2ChevronDown`}
                    />
                  )}
                </div>
              </>
            )
          } else {
            return (
              <TysiaCheckbox
                viewPath={`${props.viewPath}/TysiaCheckbox`}
                value={cell.row.getIsSelected()}
                disabled={
                  !cell.row.getIsSelected() &&
                  cell.table.getSelectedRowModel().flatRows.length >= 4
                }
                onChange={cell.row.getToggleSelectedHandler()}
              >
                {childProps => (
                  <React.Fragment>
                    <TysiaCheckboxInput
                      {...childProps}
                      viewPath={`${props.viewPath}/TysiaCheckboxInput`}
                    />
                    <TysiaCheckboxText
                      {...childProps}
                      viewPath={`${props.viewPath}/TysiaCheckboxText`}
                      text={cell.row.original.name}
                    />
                  </React.Fragment>
                )}
              </TysiaCheckbox>
            )
          }
        },
      },
      {
        header: 'Treatment Length',
        accessorKey: 'length_in_weeks',
        cell: cell => {
          if (cell.row.getCanExpand()) return null

          return (
            <span
              style={{
                opacity:
                  cell.row.getIsSelected() ||
                  cell.table.getSelectedRowModel().flatRows.length < 4
                    ? 1
                    : 0.5,
              }}
            >
              {`${convertTxPlanWeeksToMonths(
                cell.row.original.length_in_weeks
              )} Months`}
            </span>
          )
        },
      },
      {
        header: 'Treatment Fee',
        accessorKey: 'fee',
        cell: cell => {
          if (cell.row.getCanExpand()) return null

          return (
            <span
              style={{
                opacity:
                  cell.row.getIsSelected() ||
                  cell.table.getSelectedRowModel().flatRows.length < 4
                    ? 1
                    : 0.5,
              }}
            >
              {dataValueFormat(cell.getValue(), { isCurrency: true })}
            </span>
          )
        },
      },
    ],
    [props.collapseIconColor, props.viewPath, scrollToId]
  )

  let table = useReactTable({
    columns,
    data: props.value,
    initialState: {
      expanded: true,
    },
    state: {
      rowSelection,
    },
    getRowId: row => row.id,
    getSubRows: row => row.plans || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
  })

  return (
    <View {...props}>
      <div
        style={{
          width: '100%',
          maxWidth: '840px',
          overflowY: 'auto',
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          marginBottom: props.marginBottom,
          alignSelf: 'center',
        }}
      >
        <table
          style={{
            fontFamily: props.fontFamily,
            fontWeight: props.fontWeight,
            fontSize: props.fontSize,
            color: props.color,
            borderSpacing: 0,
            borderRadius: props.borderRadius,
            borderWidth: props.borderWidth,
            borderStyle: props.borderStyle,
            borderColor: props.borderColor,
            overflow: props.overflow,
            width: '100%',
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, i, list) => {
                  let isFirst = i === 0
                  return (
                    <th
                      key={header.id}
                      style={{
                        cursor: props.cursorHeader,
                        backgroundColor: props.backgroundColorHeader,
                        borderBottomWidth: props.borderBottomWidthHeader,
                        borderBottomStyle: props.borderBottomStyleHeader,
                        borderBottomColor: props.borderBottomColorHeader,
                        borderLeftWidth: !isFirst
                          ? props.borderLeftWidthHeader
                          : 0,
                        borderLeftStyle: !isFirst
                          ? props.borderLeftStyleHeader
                          : 'none',
                        borderLeftColor: !isFirst
                          ? props.borderLeftColorHeader
                          : 'transparent',
                        height: props.heightHeader,
                        fontWeight: props.fontWeightHeader,
                        fontSize: props.fontSizeHeader,
                        paddingLeft: props.paddingLeftHeader,
                        paddingRight: props.paddingRightHeader,
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              <td colspan="3" style={{ padding: 0 }}>
                <Header
                  {...props}
                  table={table}
                  viewPath={`${props.viewPath}/Header`}
                />
              </td>
            </tr>

            {table.getRowModel().rows.map((row, i, list) => {
              let isLast = i === list.length - 1
              let backgroundColor
              if (row.getCanExpand() || row.getIsSelected()) {
                backgroundColor = props.backgroundColorRowSelected
              } else if (
                !!props.onGetBackgroundRowColor &&
                typeof props.onGetBackgroundRowColor === 'function'
              ) {
                backgroundColor = props.onGetBackgroundRowColor(row)
              } else {
                backgroundColor = props.backgroundColorRow
              }
              return (
                <tr
                  key={row.id}
                  onClick={() => {
                    if (row.getCanExpand()) {
                      row.toggleExpanded()
                    } else if (
                      row.getIsSelected() ||
                      table.getSelectedRowModel().flatRows.length < 4
                    ) {
                      row.toggleSelected()
                    }
                  }}
                  style={{
                    height: props.heightRow,
                    color: props.colorRow,
                    cursor: 'pointer',
                    backgroundColor,
                  }}
                >
                  {row.getVisibleCells().map((cell, i, ll) => {
                    let isLastColumn = i === ll.length - 1
                    return (
                      <td
                        style={{
                          borderBottomWidth: isLast
                            ? 0
                            : props.borderBottomWidthCell,
                          borderBottomStyle: isLast
                            ? 'none'
                            : props.borderBottomStyleCell,
                          borderBottomColor: isLast
                            ? 'transparent'
                            : props.borderBottomColorCell,
                          paddingLeft: props.paddingLeftCell,
                          paddingRight: props.paddingRightCell,

                          borderRightWidth: !isLastColumn
                            ? props.borderBottomWidthCell
                            : 0,
                          borderRightStyle: !isLastColumn
                            ? props.borderBottomStyleCell
                            : 'none',
                          borderRightColor: !isLastColumn
                            ? props.borderBottomColorCell
                            : 'transparent',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </View>
  )
}

export default function Logic({
  backgroundColorHeader = '#F5F7FA',
  backgroundColorRow = 'transparent',
  backgroundColorRowSelected = '#F5F7FA',
  borderBottomWidthCell = 1,
  borderBottomWidthHeader = 1,
  borderBottomStyleCell = 'solid',
  borderBottomStyleHeader = 'solid',
  borderBottomColorCell = '#E4E7EB',
  borderBottomColorHeader = '#E4E7EB',
  borderLeftWidthHeader = 1,
  borderLeftStyleHeader = 'solid',
  borderLeftColorHeader = '#E4E7EB',
  borderColor = '#E4E7EB',
  borderRadius = 8,
  borderStyle = 'solid',
  borderWidth = 1,
  color = '#152935',
  colorRow = '#152935',
  cursorHeader = 'pointer',
  fontFamily = 'Inter',
  fontSize = 12,
  fontWeight = 400,
  fontWeightHeader = 500,
  fontSizeHeader = '13px',
  heightHeader = 36,
  heightRow = 44,
  marginBottom = 0,
  marginLeft = 4,
  marginRight = 4,
  marginTop = 16,
  overflow = 'hidden',
  paddingLeftCell = 16,
  paddingLeftHeader = 16,
  paddingRightCell = 16,
  paddingRightHeader = 16,
  onGetBackgroundRowColor = null,
  collapseIconColor = '#9AA5B1',
  disableColor = '#E4E7EB',
  ...props
}) {
  return (
    <TreatmentTable
      backgroundColorHeader={backgroundColorHeader}
      backgroundColorRow={backgroundColorRow}
      backgroundColorRowSelected={backgroundColorRowSelected}
      borderBottomWidthCell={borderBottomWidthCell}
      borderBottomWidthHeader={borderBottomWidthHeader}
      borderBottomStyleCell={borderBottomStyleCell}
      borderBottomStyleHeader={borderBottomStyleHeader}
      borderBottomColorCell={borderBottomColorCell}
      borderBottomColorHeader={borderBottomColorHeader}
      borderLeftWidthHeader={borderLeftWidthHeader}
      borderLeftStyleHeader={borderLeftStyleHeader}
      borderLeftColorHeader={borderLeftColorHeader}
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderStyle={borderStyle}
      borderWidth={borderWidth}
      color={color}
      colorRow={colorRow}
      cursorHeader={cursorHeader}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontWeightHeader={fontWeightHeader}
      fontSizeHeader={fontSizeHeader}
      heightHeader={heightHeader}
      heightRow={heightRow}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      overflow={overflow}
      paddingLeftCell={paddingLeftCell}
      paddingLeftHeader={paddingLeftHeader}
      paddingRightCell={paddingRightCell}
      paddingRightHeader={paddingRightHeader}
      onGetBackgroundRowColor={onGetBackgroundRowColor}
      collapseIconColor={collapseIconColor}
      disableColor={disableColor}
      {...props}
    />
  )
}
