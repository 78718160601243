import { useMemo } from 'react'
import View from './view.js'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let is_emergency = useDataValue({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
    path: 'is_emergency',
  })

  let emergency_types = useMemo(() => {
    if (!is_emergency) return []
    return props?.from.filter(template => template.is_emergency) ?? []
  }, [is_emergency, props.from])

  let other_types = useMemo(() => {
    return (
      props.from?.filter(template =>
        is_emergency ? !template.is_emergency : true
      ) ?? []
    )
  }, [is_emergency, props.from])

  return (
    <View
      {...props}
      emergency_types={emergency_types}
      other_types={other_types}
    />
  )
}
