import { v4 as uuid } from 'uuid'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'setLocation': {
        if (
          value.selected.location_id === args.location_id &&
          value.selected.vaxiom_location_id === args.vaxiom_location_id &&
          value.selected.time_zone_id === args.time_zone_id
        )
          return

        change(next => {
          // Change location
          next.selected.location_id = args.location_id
          next.selected.vaxiom_location_id = args.vaxiom_location_id
          next.selected.location_name = args.location_name

          // Update date according to new location timezone
          let currentUtcDate = zonedTimeToUtc(
            value.selected.date,
            value.selected.time_zone_id
          )
          next.selected.date = utcToZonedTime(currentUtcDate, args.time_zone_id)
          next.selected.time_zone_id = args.time_zone_id

          // Reset scheduling
          next.scheduling = originalValue.scheduling

          if (args.show_loading_overlay) {
            next.appointment_overlay.is_location_changing = true
          }
        })

        break
      }
      case 'setDate': {
        change(next => {
          next.selected.date = args.date
        })
        break
      }
      case 'setOptimalDate': {
        change(next => {
          next.selected.date = args.date
          next.optimal_date = args.date
        })
        break
      }
      case 'appointmentOverlay/open': {
        change(next => {
          next.appointment_overlay.open = true
          next.appointment_overlay.appointment_id = args.appointment_id
          next.scheduling = originalValue.scheduling
          if ('mode' in args) next.appointment_overlay.mode = args.mode
        })
        break
      }
      case 'appointmentOverlay/close': {
        change(next => {
          next.appointment_overlay.open = false
          next.appointment_overlay.patient_id = null
          next.appointment_overlay.appointment_id = null
          next.scheduling = originalValue.scheduling
          next.optimal_date = originalValue.optimal_date
        })
        break
      }
      case 'scheduling/selectUntemplatedSlot': {
        change(next => {
          let id = `${uuid()}-candidate-untemplated`
          next.scheduling.slot_id = null
          next.scheduling.untemplated_slot = {
            id,
            start_min: args.start_min,
            end_min: args.end_min,
            chair_id: args.chair_id,
            location_id: next.selected.location_id,
            date: args.date,
          }
          if (args.focus) next.focused_event_id = id
        })
        break
      }
      case 'scheduling/setTemplatedSlots': {
        let id = args.slot_id
          ? args.slot_id
          : args.untemplated_slot
          ? `${uuid()}-candidate-untemplated`
          : null

        change(next => {
          next.scheduling = {
            ...originalValue.scheduling,
            appointment_id: args.appointment_id,
            appointment_type_name: args.appointment_type_name,
            slots: args.slots,
            duration: args.duration,
            slot_id: args.slot_id ? id : null,
            untemplated_slot: args.untemplated_slot
              ? {
                  id,
                  start_min: args.untemplated_slot.start_min,
                  end_min: args.untemplated_slot.end_min,
                  chair_id: args.untemplated_slot.chair_id,
                  date: args.untemplated_slot.date,
                  location_id: next.selected.location_id,
                }
              : originalValue.scheduling.untemplated_slot,
          }
          next.focused_event_id = id
        })
        break
      }
      case 'scheduling/selectTemplatedSlot': {
        change(next => {
          next.scheduling.slot_id = args.id
          next.scheduling.untemplated_slot =
            originalValue.scheduling.untemplated_slot
          if (args.focus) next.focused_event_id = args.id
        })
        break
      }
      case 'scheduling/deselectAnySlot': {
        change(next => {
          next.scheduling.slot_id = null
          next.scheduling.untemplated_slot =
            originalValue.scheduling.untemplated_slot
        })
        break
      }
      case 'scheduling/reset': {
        change(next => {
          next.scheduling = originalValue.scheduling
          if ('optimal_date' in args) next.optimal_date = args.optimal_date
          if ('date' in args) next.selected.date = args.date
          if ('location' in args) {
            next.selected.location_id = args.location.location_id
            next.selected.vaxiom_location_id = args.location.vaxiom_location_id
            next.selected.location_name = args.location.location_name
            next.selected.time_zone_id = args.location.time_zone_id
          }
        })
        break
      }
      case 'removeFocus': {
        change(next => {
          next.focused_event_id = null
        })
        break
      }
      default: {
        break
      }
    }
  }
}
