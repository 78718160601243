import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'change_appointment_duration',
    viewPath: props.viewPath,
  })
  let current_appointment_duration = useDataValue({
    context: 'event',
    path: 'appointment.duration',
    viewPath: props.viewPath,
  })
  let new_appointment_duration = useDataValue({
    context: 'change_appointment_duration',
    path: 'duration',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    current_appointment_duration === new_appointment_duration ||
    !new_appointment_duration ||
    new_appointment_duration <= 0
  )
}
