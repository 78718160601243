import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  let duration = useDataValue({
    context: 'event',
    path: 'appointment.duration',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    return { ...data, duration }
  }, [data, duration])
}
