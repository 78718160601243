import View from './view.js'

export default function Logic(props) {
  let duration = props?.selected?.[0]?.duration

  if (typeof duration === 'number') {
    return (
      <View
        {...props}
        text={`Appointment is of ${duration} min duration by default.`}
      />
    )
  }

  return null
}
