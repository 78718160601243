// this is an autogenerated file from DesignSystem/TimeGrid/mutation-update-appointment-duration.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimeGrid($id: uuid!, $duration: Int!) {
  calendar_update_appointment_booking_duration(
    args: { _appointment_booking_id: $id, _duration: $duration }
  ) {
    id
    _id
  }
}

`