// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content($treatment_id: uuid!) {
  treatments_timeline(args: { _treatment_id: $treatment_id }) {
    id
    type
    created_at
    updated_at
    appointment {
      id
      _id
      unplanned
      is_diagnosis_required
      is_imaging_required
      colors
      interval_to_next
      sys_created
      has_notes
      duration
      diagnosis(limit: 1) {
        id
        _id
      }
      toothchart_snapshot(
        where: { sys_created: { _is_null: false } }
        limit: 1
      ) {
        id
        _id
      }
      patient_imageseries {
        id
      }
      booking {
        id
        _id
        state
        start_time
        local_start_date
        local_start_time
        duration
        video_link
        chair_id
        provider_id
        assistant_id
        seated_chair {
          id
          _id
          code
          resource {
            id
            organization {
              id
              _id
            }
          }
        }
        chair {
          id
          resource {
            id
            organization {
              id
              name
            }
          }
        }
        provider {
          id
          employment_contract {
            id
            person {
              id
              first_name
              last_name
            }
          }
        }
        assistant {
          id
          employment_contract {
            id
            person {
              id
              first_name
              last_name
            }
          }
        }
        location {
          id
          _id
          name
          time_zone_id
        }
      }
      type {
        id
        full_name
        display_name
        display_color_id
        color_id
        is_treatment_starter
        is_virtual
      }
    }
  }
}

`