// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/OrderRow/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_OrderRow($id: uuid!) {
  timeline_events_by_pk(id: $id) {
    id
    title: data(path: "title")
    status: data(path: "status")
    order_id: data(path: "orderId")
    tracking_id: data(path: "trackingId")
    tracking_url: data(path: "trackingUrl")
    carrier: data(path: "carrier")
    created_at
  }
}

`