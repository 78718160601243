import { isAppointmentScheduled } from 'Data/format.js'

export function getAppointmentName(event, is_new_calendar_enabled) {
  let duration = is_new_calendar_enabled
    ? `(${
        isAppointmentScheduled(event.appointment)
          ? event.appointment.booking.duration
          : event.appointment.duration
      } mins)`
    : null
  return [event.appointment.type.display_name, duration]
    .filter(Boolean)
    .join(' ')
}
