// this is an autogenerated file from App/Account/Content/Calendar/New/AppointmentOverlay/Content/Form/Content/TemplateSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_AppointmentOverlay_Content_Form_Content_TemplateSelect($location_id: uuid!) {
  vaxiom_default_appointment_templates(
    where: { location: { _id: { _eq: $location_id } } }
    limit: 1
  ) {
    id
    template {
      id
    }
  }
  vaxiom_appointment_templates(
    where: {
      deleted: { _eq: false }
      organization: { _id: { _eq: $location_id } }
    }
    order_by: { full_name_computed: asc }
  ) {
    id
    full_name_computed
    color_id_computed
    duration
    appointment_type {
      id
      _id
      emergency
    }
  }
}

`