import { useMutation } from 'Data/Api.js'
import {
  useDataSubmit,
  useDataValue,
  useDataFormat,
  useDataChange,
} from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutationNoShow from './mutation-no-show.graphql.js'
import mutationResendVirtualLink from './mutation-resend-virtual-link.graphql.js'
import mutationUpdatePlannedAppointment from './mutation-update-planned-appointment.graphql.js'
import mutationRollbackAppointmentState from './mutation-rollback-appointment-state.graphql.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })
  let patient_uuid = useDataValue({
    context: 'patient',
    path: '_id',
    viewPath: props.viewPath,
  })
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let appointments = useDataFormat({
    context: 'events',
    viewPath: props.viewPath,
    format: value =>
      value
        .filter(event => event.type === 'appointment')
        .map(event => event.appointment),
  })
  let submit = useDataSubmit({
    context: 'patient',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutationNoShow] = useMutation(mutationNoShow)
  let [, executeMutationResendVirtualLink] = useMutation(
    mutationResendVirtualLink
  )
  let [, executeMutationUpdatePlannedAppointment] = useMutation(
    mutationUpdatePlannedAppointment
  )
  let [, executeMutationRollbackAppointmentState] = useMutation(
    mutationRollbackAppointmentState
  )

  return async function onClick(action) {
    switch (action) {
      case 'GENERATE_LETTERS':
        return generateLetters()
      case 'SHOW_DIAGNOSIS':
        return showDiagnosis()
      case 'SHOW_TOOTH_CHART':
        return showToothChart()
      case 'GO_TO_APPOINTMENT':
        return goToAppointment()
      case 'RESCHEDULE':
        return reschedule()
      case 'CANCEL':
        return cancel()
      case 'CHANGE_APPOINTMENT_TYPE':
        return changeAppointmentType()
      case 'CHANGE_APPOINTMENT_DURATION':
        return changeAppointmentDuration()
      case 'MOVE_TO_ANOTHER_TREATMENT':
        return moveToAnotherTreatment()
      case 'NO_SHOW':
        return markAsNoShow()
      case 'RESEND_VIRTUAL_LINK':
        return resendVirtualLink()
      case 'SHOW_APPOINTMENT_HISTORY':
        return showAppointmentHistory()
      case 'SHOW_AUDIT_LOG':
        return showAppointmentAudit()
      case 'CHANGE_TO_NORMAL_APPOINTMENT':
        return changeToNormalAppointment()
      case 'SHOW_IMAGES':
        return showImages()
      case 'ROLLBACK_APPOINTMENT_STATE':
        return rollbackAppointmentState()
      default:
        return
    }
  }

  function generateLetters() {
    setFlowTo(normalizePath(props.viewPath, 'GenerateLetters/Content'))
  }

  function showAppointmentAudit() {
    setFlowTo(normalizePath(props.viewPath, 'Audit/Content'))
  }

  function showDiagnosis() {
    setFlowTo(normalizePath(props.viewPath, 'Diagnosis/Content'))
  }

  function showToothChart() {
    setFlowTo(normalizePath(props.viewPath, 'ToothChart/Content'))
  }

  function cancel() {
    setFlowTo(normalizePath(props.viewPath, 'Cancel/Content'))
  }

  function changeAppointmentType() {
    setFlowTo(normalizePath(props.viewPath, 'ChangeAppointmentType/Content'))
  }

  function changeAppointmentDuration() {
    submit({
      type: 'showCalendar',
      coreParams: {
        patient_id,
        appointment_id: appointment.id,
        date: appointment.booking.local_start_date,
        mode: 'day',
        is_rescheduling: true,
      },
      patient_id: patient_uuid,
      appointment_id: appointment._id,
      appointment_booking_location: appointment.booking.location,
    })
  }

  function moveToAnotherTreatment() {
    setFlowTo(normalizePath(props.viewPath, 'MoveToAnotherTreatment/Content'))
  }

  function showAppointmentHistory() {
    setFlowTo(normalizePath(props.viewPath, 'AppointmentHistory/Content'))
  }

  function goToAppointment() {
    submit({
      type: 'showCalendar',
      coreParams: {
        patient_id,
        appointment_id: appointment.id,
        date: appointment.booking.local_start_date,
        mode: 'day',
        is_rescheduling: false,
      },
      patient_id: patient_uuid,
      appointment_id: appointment._id,
      appointment_booking_id: appointment.booking._id,
      appointment_booking_location: appointment.booking.location,
      date: appointment.booking.local_start_date,
      only_show_appointment: true,
    })
  }

  function reschedule() {
    submit({
      type: 'showCalendar',
      coreParams: {
        patient_id,
        appointment_id: appointment.id,
        is_rescheduling: true,
      },
      patient_id: patient_uuid,
      appointment_id: appointment._id,
      appointment_booking_location: appointment.booking.location,
    })
  }

  async function markAsNoShow() {
    let mutationResponse = await executeMutationNoShow({
      id: appointment.booking._id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem marking the appointment as no show. Please try again.`
        )
      )
    }
  }

  async function resendVirtualLink() {
    let mutationResponse = await executeMutationResendVirtualLink({
      id: appointment.booking._id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem resending the virtual link for the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess('The virtual link was sent successfully'))
  }

  async function changeToNormalAppointment() {
    let index = appointments.findIndex(el => el.id === appointment._id)
    let previous = appointments
      .slice(0, index)
      .filter(el => !el.unplanned)
      .pop()
    let next = appointments
      .slice(index + 1)
      .filter(el => !el.unplanned)
      .shift()
    let updates = []
    if (previous) {
      updates.push({
        _set: {
          next_appointment_id: appointment.id,
        },
        where: {
          _id: { _eq: previous._id },
        },
      })
    }
    updates.push({
      _set: {
        next_appointment_id: next ? next.id : null,
        unplanned: false,
      },
      where: {
        _id: { _eq: appointment._id },
      },
    })

    let mutationResponse = await executeMutationUpdatePlannedAppointment({
      updates,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the appointment. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess('The appointment was updated successfully'))
  }

  function showImages() {
    change(next => {
      next.image_series_id = appointment.patient_imageseries?.[0].id
      next.image_id = null
    })
  }

  async function rollbackAppointmentState() {
    let mutationResponse = await executeMutationRollbackAppointmentState({
      id: appointment.booking._id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Cannot rollback the state of the selected appointment. Please try again.'
        )
      )
      return
    }

    change(next => {
      if (next.ignored_booking_ids) {
        // show booking on upcoming appointments section
        next.ignored_booking_ids = next.ignored_booking_ids.filter(
          id => id !== appointment.booking._id
        )
      }
    })
  }
}
