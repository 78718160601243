import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'treatment_plan_group',
    path: 'id',
    viewPath: props.viewPath,
  })
  let setScrollToId = useDataChange({
    context: 'scroll_to',
    path: 'id',
    viewPath: props.viewPath,
  })
  return function onClick() {
    setScrollToId(id)

    if (props.closePopover) {
      props.closePopover()
    }
  }
}
