import { useEffect } from 'react'
import { ERROR, useNotifications } from 'Logic/Notifications.js'
import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import View from './view.js'

let NOTIFICATION_ID = 'change-back-location'

export default function Logic(props) {
  let templates = useDataValue({
    viewPath: props.viewPath,
    context: 'template_select',
  })

  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'appointment_id',
  })

  let appointment_type_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'appointment_type_id',
  })

  let appointment_type_name = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'appointment_type_name',
  })

  let location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })

  let location_name = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.location_name',
  })

  let initial_location = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'initial_location',
  })

  let submitTab = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  let [, notify, close] = useNotifications()

  useEffect(() => {
    if (!appointment_type_id || !templates?.length) return

    let appointment_type = templates.find(v => v.id === appointment_type_id)
    if (!appointment_type) {
      submit({
        type: 'setAppointmentTemplate',
        template_exists_in_location: false,
      })

      let action =
        !initial_location || initial_location?.id === location_id
          ? null
          : {
              text: `Switch back to last location`,
              onClick: async () => {
                await submitTab({
                  type: 'setLocation',
                  location_id: initial_location.id,
                  vaxiom_location_id: initial_location.vaxiom_id,
                  time_zone_id:
                    initial_location.time_zone_id || DEFAULT_TIMEZONE,
                  location_name: initial_location.name,
                })

                close({ id: NOTIFICATION_ID })
              },
            }

      notify({
        status: ERROR,
        title: null,
        action,
        id: NOTIFICATION_ID,
        message: `The appointment ${appointment_type_name} does not exist in ${location_name}`,
      })
    } else {
      submit({
        type: 'setAppointmentTemplate',
        appointment_type_id: appointment_type.id,
        appointment_type_name: appointment_type.text,
        template_id: appointment_type.template_id,
        duration: appointment_type.duration,
        reset: false,
        template_exists_in_location: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment_type_id, appointment_id, templates])

  return <View {...props} />
}
