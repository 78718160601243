import { useDataValue, useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })
  let is_emergency = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_emergency',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'patient_id',
  })

  return function onChange(value) {
    if (is_emergency) {
      let emergency_type = value?.find(item => item.is_emergency)
      if (emergency_type) {
        return submit({
          type: 'setAppointmentTemplate',
          appointment_type_id: emergency_type.id,
          appointment_type_name: emergency_type.text,
          template_id: emergency_type.template_id,
          duration: emergency_type.duration,
          reset: false,
          template_exists_in_location: true,
        })
      } else return
    }
    if (is_external_any || patient_id) return
    let preferred = value?.find(item => item.is_default === true)
    let selected = preferred || value?.[0]
    if (selected) {
      return submit({
        type: 'setAppointmentTemplate',
        appointment_type_id: selected.id,
        appointment_type_name: selected.text,
        template_id: selected.template_id,
        duration: selected.duration,
        reset: false,
        template_exists_in_location: true,
      })
    }
  }
}
