import { useDataSubmit, useDataValue, useDataFormat } from 'Simple/Data.js'
import { format } from 'date-fns-tz'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let appointment_type_name = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_type_name',
    viewPath: props.viewPath,
  })
  let duration = useDataValue({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })
  let booking = useDataValue({
    context: 'appointment_overlay',
    path: 'booking',
    viewPath: props.viewPath,
  })
  let has_calendar_schedule_conflict = useDataValue({
    context: 'global',
    path: 'current_location.permissions.has_calendar_schedule_conflict',
    viewPath: props.viewPath,
  })
  let tab_date = useDataFormat({
    context: 'tab',
    path: 'selected.date',
    format: value => format(value, 'yyyy-MM-dd'),
    viewPath: props.viewPath,
  })
  let tab_location_id = useDataValue({
    context: 'tab',
    path: 'selected.location_id',
    viewPath: props.viewPath,
  })
  let tab_scheduling = useDataValue({
    context: 'tab',
    path: 'scheduling',
    viewPath: props.viewPath,
  })

  return async function onChange(value) {
    if (!value) return

    let slots = value.slots
    let selectedSlot = {
      slot_id: null,
      untemplated_slot: null,
    }

    // Check if we already have a templated slot selected
    if (
      tab_scheduling.slot_id &&
      slots.find(slot => slot.id === tab_scheduling.slot_id)
    ) {
      selectedSlot.slot_id = tab_scheduling.slot_id
      // Check if we already have an untemplated slot selected
    } else if (
      tab_scheduling.untemplated_slot?.id &&
      tab_date === tab_scheduling.untemplated_slot.date &&
      tab_location_id === tab_scheduling.untemplated_slot.location_id
    ) {
      selectedSlot.untemplated_slot = {
        ...tab_scheduling.untemplated_slot,
        end_min: tab_scheduling.untemplated_slot.start_min + duration,
      }
      // Check if we have an appointment and its booking
    } else if (
      appointment_id &&
      booking &&
      tab_date === booking.date &&
      tab_location_id === booking.location_id &&
      typeof booking.start_min === 'number' &&
      booking.chair_id
    ) {
      // Search for booking within array of slots
      let templatedSlot = slots?.find(
        slot =>
          slot.date === booking.date &&
          slot.start_min === booking.start_min &&
          slot.chair_id === booking.chair_id
      )

      if (templatedSlot?.id) {
        selectedSlot.slot_id = templatedSlot.id
      } else if (has_calendar_schedule_conflict) {
        selectedSlot.untemplated_slot = {
          start_min: booking.start_min,
          end_min: booking.start_min + duration,
          date: booking.date,
          chair_id: booking.chair_id,
        }
      }
    }

    await submitTab({
      type: 'scheduling/setTemplatedSlots',
      slots,
      appointment_id,
      appointment_type_name,
      duration,
      ...selectedSlot,
    })
  }
}
