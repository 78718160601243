import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import isEqual from 'lodash/isEqual.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let id = useDataValue({
    context: 'connection_resources',
    viewPath: props.viewPath,
    path: 'resources[0].id',
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'save': {
        let properties = Object.fromEntries(
          value.steps
            .filter(step => step.value !== null)
            .map(step => [step.key, step.value])
        )

        let originalProperties = Object.fromEntries(
          originalValue.steps
            .filter(step => step.value !== null)
            .map(step => [step.key, step.value])
        )

        if (!isEqual(properties, originalProperties)) {
          let mutationResponse = await executeMutation({
            id,
            properties,
          })

          if (mutationResponse.error) {
            return notify(notifyError('An error has occurred saving settings'))
          }
        }

        change(next => {
          next.current_index = value.current_index + 1
        })

        if (value.current_index === value.steps.length - 1) {
          setFlowTo(normalizePath(props.viewPath, '../../../No'))
        }

        return
      }
      default: {
      }
    }
  }
}
