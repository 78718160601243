import { useMemo } from 'react'

let NO_GROUP_LABEL = 'OTHER TX PLANS'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data?.length) return null

    let groups = data.reduce((acc, item) => {
      let { tx_plan_group: group, ...rest } = item

      let groupId = group ? group.id : 0

      if (!acc[groupId]) {
        acc[groupId] = {
          id: groupId,
          name: group ? group.name : NO_GROUP_LABEL,
          plans: [],
        }
      }

      acc[groupId].plans.push(rest)

      return acc
    }, {})

    return Object.values(groups).sort((a, b) => a.name.localeCompare(b.name))
  }, [data])
}
