import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let data = useDataValue({
    context: 'template_select',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let selected = value ? data.find(item => item.id === value) : null
    if (selected) {
      submit({
        type: 'setAppointmentTemplate',
        appointment_type_id: selected.id,
        appointment_type_name: selected.text,
        template_id: selected.template_id,
        duration: selected.duration,
        reset: true,
        template_exists_in_location: true,
      })
    }
  }
}
