import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'
import { isUnscheduledAppointment } from 'Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let selected = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })

  return () => {
    if (appointment._id === selected) return
    if (appointment.id === 'new') {
      submit({
        type: 'setNewAppointment',
        approximateDate: appointment.approximateDate,
      })
    } else {
      let is_unscheduled = isUnscheduledAppointment(appointment)
      let booking = !is_unscheduled ? appointment.booking?.[0] : null
      let location = !is_unscheduled
        ? booking.location
        : appointment.tx.organization
      submit({
        type: 'setExistingAppointment',
        id: appointment._id,
        type_id: appointment.type._id,
        type_name: appointment.type.display_name,
        treatment_id: appointment.tx._id,
        duration: booking ? booking.duration : appointment.duration,
        approximateDate: appointment.approximateDate,
        location: {
          id: location._id,
          vaxiom_id: location.id,
          name: location.name,
          time_zone_id: location.time_zone_id,
        },
        booking: booking
          ? {
              start_min: timeToMinutes(booking.local_start_time),
              date: booking.local_start_date,
              chair_id: booking.chair._id,
              location_id: booking.chair.resource.organization._id,
            }
          : null,
      })
    }
    props.closePopover?.()
  }
}
