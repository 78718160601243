import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let emails = data.emails ?? []
    let phones = data.phones ?? []

    let uniqueEmails = emails
      .filter(item => item.email)
      .reduce((acc, current) => {
        if (
          !acc[current.email.address] ||
          (acc[current.email.address] && current.preference)
        ) {
          acc[current.email.address] = current
        }

        return acc
      }, {})

    let uniquePhones = phones
      .filter(item => item.phone)
      .reduce((acc, current) => {
        if (
          !acc[current.phone.number] ||
          (acc[current.phone.number] && current.preference)
        ) {
          acc[current.phone.number] = current
        }

        return acc
      }, {})

    return {
      emails: Object.keys(uniqueEmails).map(key => uniqueEmails[key]),
      phones: Object.keys(uniquePhones).map(key => uniquePhones[key]),
    }
  }, [data])
}
