// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/Content/mutation-update-preferences.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content_Content($id: uuid!, $properties: jsonb!) {
  update_apps_connection_resources_by_pk(
    pk_columns: { id: $id }
    _set: { properties: $properties }
  ) {
    id
    connection_id
    resource_id
    properties
  }
}

`