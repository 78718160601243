import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data?.length) return null

    let [connection_resource] = data

    return {
      id: connection_resource.connection.id,
      step: 'account',
      account_id: connection_resource.properties?.accountId,
      doctor_id: connection_resource.properties?.doctorId,
      image_series_id,
      set_preferred: true,
    }
  }, [data, image_series_id])
}
