import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'treatment_plan_template',
    path: 'id',
    viewPath: props.viewPath,
  })
  let table = useDataValue({
    context: 'table',
    viewPath: props.viewPath,
  })

  return function onClick() {
    let row = table.getRowModel().rows.find(r => r.original.id === id)
    if (row) {
      row.toggleSelected()
    }
  }
}
