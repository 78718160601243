import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'patient_id',
  })
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'appointment_id',
  })
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'treatment_id',
  })

  let selected = useDataValue({
    context: 'tab',
    path: 'selected',
    viewPath: props.viewPath,
  })

  let mode = useDataValue({
    context: 'tab',
    path: 'appointment_overlay.mode',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data) return data

    let newData = {
      ...data,
      ...(patient_id
        ? {
            patient_id,
            is_external_patient_id: true,
          }
        : {}),
      ...(patient_id || mode === 'emergency'
        ? {
            patient_option: 'existing',
          }
        : {}),
      ...(appointment_id
        ? {
            appointment_id,
            is_external_appointment_id: true,
          }
        : {}),
      ...(treatment_id ? { treatment_id, is_external_treatment_id: true } : {}),
      initial_location: {
        id: selected.location_id,
        vaxiom_id: selected.vaxiom_location_id,
        name: selected.location_name,
        time_zone_id: selected.time_zone_id,
      },
      is_emergency: mode === 'emergency',
    }

    newData.is_external_any =
      newData.is_external_patient_id === true ||
      newData.is_external_appointment_id === true ||
      newData.is_external_treatment_id === true

    return newData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, patient_id, appointment_id, treatment_id, mode])
}
