import React from 'react'
import { DataProvider } from 'Simple/Data.js'

import View from './view'

let value = { id: null }

export default function Logic(props) {
  return (
    <DataProvider context="scroll_to" value={value} viewPath={props.viewPath}>
      <View {...props} />
    </DataProvider>
  )
}
