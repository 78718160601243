import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

export default function useDataOnSubmit(props) {
  let appointment = useDataValue({
    context: 'event',
    path: 'appointment',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value }) {
    if (!value.duration || value.duration <= 0) return
    if (value.duration === appointment.duration) return

    let mutationResponse = await executeMutation({
      appointment_id: appointment.id,
      duration: value.duration,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem changing the appointment duration. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`Appointment duration changed!`))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
