// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content(
  $location_id: uuid!
  $app_id: uuid!
  $connection_id: uuid!
  $resource_id: uuid!
) {
  apps_automations(location_id: $location_id, app_id: $app_id) {
    id
    automations
  }
  apps_connections_by_pk(id: $connection_id) {
    id
    app {
      id
      properties: metadata(path: "connection.properties")
    }
    resources(where: { resource_id: { _eq: $resource_id } }) {
      id
      properties
    }
  }
}

`